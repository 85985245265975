/* Base Styles */
:root {
    --bg-color-dark: rgb(45, 48, 55);
    --text-color-dark: white;
    --header-bg-color-dark: rgba(0, 0, 0, 0.2);
    --bg-color-light: white;
    --text-color-light: #000000;
    --header-bg-color-light: rgba(247, 247, 249, 0.8);
    --button-bg-color: #82d7f7; /* blue */
    --button-border-color: #82d7f7; /* blue */
    --button-hover-bg-color: #82d7f7; /* dark blue */
    --github-box-text-color-light: #000000;
    --github-box-text-color-dark: white;
    --form-bg-color-dark: #444d56; /* dark gray */
    --form-text-color-dark: white;
    --form-bg-color-light: #fafbfc; /* light gray */
    --input-bg-color-dark: #444d56; /* dark gray */
    --input-text-color-dark: white;
    --input-bg-color-light: #fafbfc; /* light gray */
}

/* Body Styles */
body {
    display: flex;
    justify-content: center;
}

body.dark-mode {
    background-color: var(--bg-color-dark);
    color: var(--text-color-dark);
}

body.light-mode {
    background-color: var(--bg-color-light);
    color: var(--text-color-light);
}

/* Header Styles */
.header {
    align-items: center;
    width: auto;
    max-width: 800px;
    height: fit-content;
    margin: 4% auto 0;
    padding: 10px 20px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
}

body.dark-mode .header {
    background-color: var(--header-bg-color-dark);
}

body.light-mode .header {
    background-color: var(--header-bg-color-light);
}

/* Header Content Styles */
h1 {
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    padding: 5px 20px;
}

p {
    width: auto;
    max-width: 800px;
    margin-top: 15px;
    text-align: left;
    line-height: 1.5;
    padding: 5px 20px;
}

/* Image Styles */
.img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid var(--button-border-color);
    margin-left: 15px;
}

/* Links and Buttons Styles */
.links,
.social-link,
.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.button {
    background-color: var(--button-bg-color);
    border: 2px solid var(--button-border-color);
    color: var(--text-color);
    padding: 8px 8px;
    font-size: 14px;
    border-radius: 50px;
    cursor: pointer;
    margin-left: 15px;
}

.button:hover {
    background-color: var(--button-hover-bg-color);
    color: var(--text-color);
}

/* Media Query for Medium Screens */
@media (max-width: 960px) {
    .button:hover {
        background-color: var(--button-bg-color);
        color: var(--text-color);
    }

    .header {
        border-radius: 0%;
        align-items: center;
        text-align: left;
        margin: 0%;
    }

    meta[name="theme-color"] {
        background-color: var(--button-border-color);
    }
}

/* Shared Styles for Sections */
.skills,
.tools,
.projects,
.about-me {
    width: auto;
    text-align: left;
}

.skills h1,
.tools h1,
.projects h1,
.about-me h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.skills p,
.tools p,
.projects p,
.about-me p {
    margin-top: 20px;
}

.skills img,
.tools img,
.projects img {
    margin: 0 10px;
}

/* GitHub Box Styles */
body.dark-mode .github-box {
    background-color: var(--bg-color-dark);
    color: var(--text-color-dark);
}

body.light-mode .github-box {
    background-color: var(--github-box-bg-color-light);
    color: var(--github-box-bg-color-light);
}

body.light-mode .github-link:hover {
    background-color: var(--button-hover-bg-color-light);
}

.github-box {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: left;
    max-width: 800px;
    margin: 20px auto;
    transition: background-color 0.3s;
    border: 2px solid var(--button-border-color);
}

.github-box h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.github-box p {
    font-size: 16px;
    margin-bottom: 20px;
}

.github-link {
    display: inline-block;
    color: #82d7f7; /* blue */;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 50px;
    border-color: var(--button-border-color);
    border: 2px solid var(--button-border-color);
    transition: background-color 0.3s;
}

.github-link:hover {
    background-color: var(--button-hover-bg-color-light);
    color: #82d7f7; /* blue */;
}

/* Search Bar Styles */
.header .search-bar {
    margin: 0px 20px;
}

.header .search-bar input[type="search"] {
    height: 40px;
    padding: 8px 8px;
    font-size: 14px;
    border: 2px solid var(--button-border-color);
    border-radius: 20px;
    background-color: transparent;
    color: var(--text-color);
}

/* Form Styles */
.form {
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
}

.form h1,
.form h2 {
    margin-bottom: 8px;
}

.form input[type="text"],
.form input[type="email"],
.form textarea {
    background-color: var(--input-bg-color-dark);
    color: var(--input-text-color-dark);
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 16px;
}

body.light-mode .form {
    background-color: var(--form-bg-color-light);
    color: var(--form-text-color-light);
}

body.light-mode .form input[type="text"],
body.light-mode .form input[type="email"],
body.light-mode .form textarea {
    background-color:  var (--input-bg-color-light); /* light gray */;
    color: var(--input-text-color-light);
}

.form button[type="submit"] {
    font-size: 18px;
    height: 40px;
    width: fit-content;
    background-color: var(--button-bg-color);
    color: var(--text-color);
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form button[type="submit"]:hover {
    background-color: var(--button-hover-bg-color);
}

/* Footer Styles */
.footer {
    align-items: center;
    background-color: #f8f9fa00;
    text-align: center;
    padding: 10px;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
}